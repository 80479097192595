import React from 'react';
import AppContext from '../../context/AppContext';
import {Toast} from 'primereact/toast';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {QueryParameter, Task} from 'two-core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Card} from 'primereact/card';
import {Panel} from 'primereact/panel';
import TasksService from '../../services/TasksService';
import './TaskComponent.scss';
import {MessageService, ToastService} from 'two-app-ui';
import {AppToolbar} from '../AppFrame/AppToolbar/AppToolbar';

interface RouteProps {
  runId: string;
  id: string;
}

interface State {
  loading: boolean;
  task?: Task;
}

class TaskComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  tasksService?: TasksService;
  toastService?: ToastService;
  appToolbarRef?: React.RefObject<AppToolbar>;

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.state = {
      loading: false,
    };

    this.initAppToolbar = this.initAppToolbar.bind(this);

    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.tasksService = this.context.tasksService;
    this.toastService = this.context.toastService;
    this.appToolbarRef = this.context.appToolbarRef;

    const id = this.props.match.params.id;
    this.loadTask(id);
  }
  async loadTask(id: string) {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    await this.tasksService
      ?.getTasks(params)
      .then(data => {
        const dataRecords = (data?.records as Task[]) ?? [];
        const task = dataRecords[0];
        const title = task?.type.toUpperCase() ?? '';
        this.initAppToolbar(title);

        this.setState({
          task: dataRecords[0],
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  initAppToolbar(title: string) {
    const {match} = this.props;
    const runId = match.params.runId;
    const backTitle = 'Run';
    const backUrl = `/run/${runId}`;
    this.appToolbarRef?.current?.initAppToolbar({
      title,
      backUrl,
      backTitle,
    });
  }

  render() {
    const {task, loading} = this.state;

    const executedTask = !!task?.executed_on;
    return (
      <>
        {loading && (
          <div className="overlay">
            <ProgressSpinner className="overlay-spinner" />
          </div>
        )}
        <ScrollPanel id="task_component">
          {task && (
            <Panel>
              <div className="p-mb-3">
                <label>task</label>
              </div>
              <Card>
                <div className="p-d-flex p-jc-center p-my-6 p-mx-3">
                  <div className="p-text-center">
                    <span className={executedTask ? 'text-line-through' : ''}>{task.description}</span>
                  </div>
                </div>
              </Card>
            </Panel>
          )}
          <Toast ref={this.toast} />
        </ScrollPanel>
      </>
    );
  }
}

export default withRouter(TaskComponent);
