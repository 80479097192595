import React from 'react';
import {NavLink} from 'react-router-dom';
import {faChevronLeft} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
  backUrl?: string;
  backTitle?: string;
  template?: () => React.JSX.Element;
}

export const LeftPart = ({backUrl, backTitle, template}: Props) => {
  if (template) {
    return <div className="app-toolbar-part">{template()}</div>;
  }

  return (
    <div className="app-toolbar-part">
      {backTitle && backUrl && (
        <NavLink className="p-button p-button-text p-button-plain p-p-0" to={backUrl}>
          <FontAwesomeIcon icon={faChevronLeft} size={'lg'} />
          <span className="p-ml-2 p-button-label">{backTitle}</span>
        </NavLink>
      )}
    </div>
  );
};
