import {FreightOrder, Task} from 'two-core';
import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {ReactNode} from 'react';
import {faMessageExclamation} from '@fortawesome/pro-light-svg-icons';
import {Card} from 'primereact/card';
import {InputSwitch} from 'primereact/inputswitch';

interface Props {
  disabled: boolean;
  tasks: Task[];
  selectedTasks: Task[];
  ordersMap: Map<string, FreightOrder>;
  onTaskSwitch: (task: Task) => void;
}

export const OrdersPanel = ({disabled, tasks, ordersMap, onTaskSwitch, selectedTasks}: Props) => {
  const panelTemplate = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = (
      <FontAwesomeIcon icon={options.collapsed ? ['fal', 'chevron-down'] : ['fal', 'chevron-up']} size={'xl'} />
    );

    const className = `${options.className}`;
    const titleClassName = `${options.titleClassName}`;
    let ordersCount = 0;
    let boxCount = 0;
    const selectedOrderIds = tasks.map(task => task.freight_order_id!);
    for (const order of Array.from(ordersMap.values())) {
      if (selectedOrderIds.includes(order.id!)) {
        ordersCount++;
        boxCount += order.shipment_items?.length ?? 0;
      }
    }

    return (
      <>
        <div className={className}>
          <div className="p-d-flex p-ai-center p-jc-between">
            <div className="p-mr-2" onClick={options.onTogglerClick}>
              {toggleIcon}
            </div>
            <span className={titleClassName + ' p-mr-2 p-as-center'}>Orders {ordersCount}</span>
          </div>
          <div className="p-d-flex p-ai-center p-jc-between">
            <span className={titleClassName + ' p-mr-3'}>Boxes {boxCount}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <Panel toggleable headerTemplate={panelTemplate} className="orders-panel p-mb-2">
      {tasks &&
        tasks.map((task, index) => {
          const cardMargin = index !== tasks!.length - 1 ? 'p-mb-3' : '';
          const executedTask = task.executed_on !== null;

          const freightOrder = ordersMap.get(task.freight_order_id!);

          const order = freightOrder?.order;
          const factoryOrder = freightOrder?.factory_order;

          const orderShipmentItems = freightOrder?.shipment_items?.filter(si => si && si.order_id === order?.id) ?? [];

          const checked = selectedTasks.includes(task);

          const boxesType =
            factoryOrder?.product_line === 'Colourvue' ? 'cv' : factoryOrder?.product_line === 'Shadesol' ? 'ss' : 'sh';
          let icon: ReactNode;
          if (task.type === 'pickup') {
            icon = <FontAwesomeIcon icon={['fas', 'arrow-alt-to-top']} size={'2x'} color="#5486ea" />;
          } else if (task.type === 'drop' || task.type === 'final-drop') {
            icon = <FontAwesomeIcon icon={['fas', 'arrow-alt-to-bottom']} size={'2x'} color="#29c76f" />;
          } else {
            icon = <FontAwesomeIcon icon={['fas', 'note']} size={'2x'} color="#ffe342" />;
          }

          const noteElement = freightOrder?.delivery_note?.length ? (
            <FontAwesomeIcon className={'p-mr-1'} icon={faMessageExclamation} />
          ) : undefined;
          const executedClass = executedTask ? 'text-line-through' : '';
          return (
            <Card key={'task' + index + task?.id?.toString()} className={cardMargin}>
              <div className="p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-p-0 p-col-1">{icon}</div>
                {order ? (
                  <>
                    <div className="p-d-flex p-p-0 p-col-8 p-flex-wrap">
                      {noteElement}
                      <span className={`p-pr-2 ${executedClass}`}>{freightOrder?.id ?? ''}</span>
                      <span className={`p-pr-2 account-bold ${executedClass}`}>
                        {freightOrder?.owner_company?.account_number ?? ''}
                      </span>
                      <span className={`p-pr-2 ${executedClass}`}>{order?.reference ?? ''}</span>
                    </div>
                    <div className="p-d-flex p-p-0 p-col-1">
                      <span className={`${executedClass}`}>{`${orderShipmentItems.length} ${boxesType}`}</span>
                    </div>
                  </>
                ) : (
                  <div className="p-d-flex p-p-0 p-col-9 p-flex-wrap">
                    <span className={`${executedClass}`}>{task.description}</span>
                  </div>
                )}
                <InputSwitch checked={checked} onChange={() => onTaskSwitch(task)} disabled={disabled} />
              </div>
            </Card>
          );
        })}
    </Panel>
  );
};
