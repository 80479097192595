import React from 'react';
import LoginForm from './LoginForm';
import './Login.scss';
import PasswordResetForm from './PasswordResetForm';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import AppContext from '../../context/AppContext';
import {AuthenticatedUser, AuthService, MessageService, UsersService} from 'two-app-ui';

interface RouteProps {
  id: string;
}

interface State {
  username: string;
  password: string;
  newPassword: string;
  passwordConfirm: string;
  authenticating: boolean;
  passwordResetRequired: boolean;
}

export class Login extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  authService: AuthService;
  userService: UsersService;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.state = {
      username: '',
      password: '',
      newPassword: '',
      passwordConfirm: '',
      authenticating: false,
      passwordResetRequired: false,
    };
    this.authService = new AuthService();
    this.userService = new UsersService(this.authService);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.login = this.login.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    this.authService = this.context.authService;
    this.userService = this.context.usersService;
  }

  login() {
    this.authService.signIn(
      this.state.username,
      this.state.password,
      async (authedUser: AuthenticatedUser) => {
        localStorage.setItem('user', JSON.stringify(authedUser));

        this.props.history.push('/');
        MessageService.sendMessage({name: 'loggedin', value: ''});
      },
      () => {
        console.error('could not auth');
        // could not auth
      },
      () => {
        this.setState({passwordResetRequired: true});
      }
    );
  }

  resetPassword() {
    this.authService
      .resetPassword(this.state.newPassword)
      .then(() => {
        // go home
        this.props.history.push('/');
      })
      .catch(error => {
        alert(error.message || JSON.stringify(error));
        //console.error(error);
      });
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <div className="login-form p-d-flex p-flex-column">
        {this.state.passwordResetRequired ? (
          <PasswordResetForm onChange={this.handleInputChange} resetPassword={this.resetPassword} />
        ) : (
          <LoginForm onChange={this.handleInputChange} login={this.login} />
        )}
      </div>
    );
  }
}

export default withRouter(Login);
