import {Button} from 'primereact/button';
import {Card} from 'primereact/card';
import {InputText} from 'primereact/inputtext';
import React from 'react';

interface PasswordResetProps {
  resetPassword: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordResetForm = ({resetPassword, onChange}: PasswordResetProps) => (
  <Card title={'Reset password'} subTitle={'Set your new Password:'} className={'p-d-flex p-flex-row'}>
    {/* new password */}
    <div className="p-field p-d-flex p-flex-column">
      <label htmlFor="username" className="p-justify-start">
        New Password
      </label>
      <div className="">
        <InputText
          id="newPassword"
          name="newPassword"
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        />
      </div>
    </div>
    {/* confirm password */}
    <div className="p-field p-d-flex p-flex-column">
      <label htmlFor="password" className="p-justify-start">
        Password
      </label>
      <div className="">
        <InputText
          id="password"
          name="password"
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        />
      </div>
    </div>
    <div className="login-button text-center p-d-flex p-flex-column">
      <Button label="Reset" onClick={() => resetPassword()} />
    </div>
  </Card>
);
export default PasswordResetForm;
