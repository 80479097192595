import {Button} from 'primereact/button';
import {Card} from 'primereact/card';
import {InputText} from 'primereact/inputtext';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface LoginProps {
  login: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const LoginForm = ({login, onChange}: LoginProps) => (
  <Card title={'Welcome'} subTitle={'Please use the form to sign in TWO app'} className={'p-d-flex p-flex-row'}>
    <div className="p-field p-d-flex p-flex-column">
      <div className="">
        <InputText
          placeholder="user name"
          id="username"
          name="username"
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        />
      </div>
    </div>
    <div className="p-field p-d-flex p-flex-column">
      <div className="">
        <InputText
          placeholder="password"
          id="password"
          name="password"
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        />
      </div>
    </div>
    <div className="login-button p-d-flex p-flex-column">
      <Button className="p-d-flex p-jc-center" onClick={() => login()}>
        <FontAwesomeIcon icon={['fal', 'sign-in-alt']} size={'lg'} />
        <span className="p-d-flex p-ml-2 p-text-bold">Login</span>
      </Button>
    </div>
  </Card>
);
export default LoginForm;
