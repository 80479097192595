import React from 'react';
import {Location} from 'two-core';
import {Dialog} from 'primereact/dialog';
import './LocationDetailDialog.scss';
import {Button} from 'primereact/button';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  location: Location | undefined;
}

class LocationDetail extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.renderFooter = this.renderFooter.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  renderFooter() {
    return (
      <div className={'p-d-flex p-justify-end  p-mt-4'}>
        <Button label="Close" className={'p-button'} onClick={() => this.props.onHide()} />
      </div>
    );
  }

  renderHeader() {
    const {location} = this.props;
    return <div className={'p-d-flex p-justify-center stop-title'}>{`${location?.name}`}</div>;
  }

  render() {
    const {location} = this.props;
    return (
      <Dialog
        id={'location_detail_component'}
        header={this.renderHeader}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '80%'}}
        modal
        onHide={this.props.onHide}
        closable={false}
      >
        <div className={'p-m-3'}>
          <div className="p-field p-grid">
            <label className="p-col-2 p-mb-0">type</label>
            <div className="p-col-10 stop-field">{location?.type ?? ''}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2 p-mb-0">street</label>
            <div className="p-col-10 stop-field">{location?.address.street ?? ''}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2 p-mb-0">suburb</label>
            <div className="p-col-10 stop-field">{location?.address.suburb ?? ''}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2 p-mb-0">postcode</label>
            <div className="p-col-10 stop-field">{location?.address.postCode ?? ''}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2 p-mb-0">state</label>
            <div className="p-col-10 stop-field">{location?.address.state ?? ''}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2 p-mb-0">phone</label>
            <div className="p-col-10 stop-field">{location?.address.phoneNumber ?? ''}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2 p-mb-0">instruction</label>
            <div className="p-col-10 stop-field">{location?.instructions ?? ''}</div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default LocationDetail;
