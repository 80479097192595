import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import React from 'react';
import {InputSwitch} from 'primereact/inputswitch';
import {InputTextarea} from 'primereact/inputtextarea';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
  collapsed: boolean;
  disabled: boolean;
  note: string;
  onVisibilityToggle: (collapse: boolean) => void;
  onNoteChange: (value: string) => void;
}

export const NotePanel = ({collapsed, disabled, note, onVisibilityToggle, onNoteChange}: Props) => {
  const panelTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className}`;
    const titleClassName = `${options.titleClassName}`;
    const toggleIcon = (
      <FontAwesomeIcon icon={options.collapsed ? ['fal', 'chevron-down'] : ['fal', 'chevron-up']} size={'xl'} />
    );

    return (
      <>
        <div className={className}>
          <div className="p-d-flex">
            <div className="p-mr-2" onClick={options.onTogglerClick}>
              {toggleIcon}
            </div>
            <div className="p-ml-2">
              <span className={titleClassName + ' p-mr-2 p-as-center'}>Note</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Panel
      toggleable
      collapsed={collapsed}
      onToggle={event => onVisibilityToggle(event.value)}
      headerTemplate={panelTemplate}
      className="p-mb-2"
    >
      <InputTextarea
        className="w-100"
        rows={5}
        cols={30}
        value={note}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onNoteChange(e.target.value)}
        disabled={disabled}
      />
    </Panel>
  );
};
