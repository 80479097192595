import React from 'react';
import AppContext from '../../context/AppContext';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {QueryParameter, Vehicle} from 'two-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Vehicle.scss';
import VehiclesService from '../../services/VehiclesServcie';
import {AuthService, UsersService} from 'two-app-ui';
import {AppToolbar} from '../AppFrame/AppToolbar/AppToolbar';
import {NavLink} from 'react-router-dom';
import {faCalendarAlt} from '@fortawesome/pro-light-svg-icons';

interface State {
  username: string;
  fullname: string;
  loading: boolean;
  vehicles: Vehicle[];
  selectedVehicle: Vehicle | undefined;
}

export class VehicleComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  authService: AuthService;
  usersService?: UsersService;
  vehiclesService?: VehiclesService;
  appToolbarRef?: React.RefObject<AppToolbar>;

  constructor(props = {}) {
    super(props);
    this.state = {
      username: '',
      fullname: '',
      loading: false,
      vehicles: [],
      selectedVehicle: undefined,
    };

    this.authService = new AuthService();
  }

  componentDidMount() {
    this.authService = this.context.authService;
    this.usersService = this.context.usersService;
    this.vehiclesService = this.context.vehiclesService;
    this.appToolbarRef = this.context.appToolbarRef;
    this.setUser();
    this.appToolbarRef?.current?.initAppToolbar({rightTemplate: this.appToolbarRightTemplate});
  }

  setUser() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userName = currentUser?.username ?? '';
    const userId = currentUser?.uuid ?? '';
    this.setState({username: userName});

    this.loadVehicles(userId);
    this.loadUser(userName);
  }

  setSelectedVehicle(value: Vehicle) {
    const vehicleId = value.id ?? '';
    localStorage.setItem('vehicleId', vehicleId.toString());

    this.setState({selectedVehicle: value});
  }

  logout() {
    this.authService.signOut();
    window.location.href = 'login';
  }

  async loadUser(username: string) {
    this.usersService
      ?.findUserbyUsername(username)
      .then(data => {
        const fullname = data.full_name ?? '';
        this.setState({
          fullname: fullname,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async loadVehicles(userId: string) {
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'state_forwarder.user_id',
        value: userId,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
      aggregate: true,
    };

    this.vehiclesService
      ?.getVehicles(params)
      .then(data => {
        const dataRecords = ((data?.records as Vehicle[]) ?? []).filter(se => se !== null);

        const value = dataRecords[0];
        this.setSelectedVehicle(value);

        this.setState({
          vehicles: dataRecords,
          selectedVehicle: value,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  appToolbarRightTemplate() {
    return (
      <NavLink className="p-button p-button-text p-button-plain p-py-1" to={'/schedule'}>
        <span className="p-button-icon">
          <FontAwesomeIcon icon={faCalendarAlt} size={'lg'} />
        </span>
        <span className="p-ml-2 p-button-label">Schedule</span>
      </NavLink>
    );
  }

  render() {
    return (
      <div className="vehicle-form p-d-flex p-flex-column">
        <Card className={'p-d-flex p-flex-row p-mb-4 bg-primary'}>
          <div className="p-field p-d-flex">
            <label htmlFor="fullname" className="p-col-6 p-as-center">
              {'You are logged in as '}
              <span className="p-text-bold full-name-label">{this.state.fullname}</span>
            </label>
            <div className="p-col-6">
              <Button className="p-d-flex w-100 p-jc-center" onClick={() => this.logout()}>
                <FontAwesomeIcon icon={['fal', 'sign-out-alt']} size={'lg'} />
                <span className="p-ml-2">Logout</span>
              </Button>
            </div>
          </div>

          <div className="p-field p-d-flex">
            <label htmlFor="vehicle" className="p-col-6 p-as-center">
              Which vehicle are you driving today?
            </label>
            <div className="p-col-6 ">
              <Dropdown
                className="p-d-flex w-100"
                value={this.state.selectedVehicle}
                options={this.state.vehicles}
                onChange={e => this.setSelectedVehicle(e.value)}
                optionLabel="name"
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default VehicleComponent;
