import React from 'react';

export type AppToolbarRightViewType = 'button' | 'menu';

interface Props {
  template?: () => React.JSX.Element;
}

export const RightPart = ({template}: Props) => {
  return <div className="app-toolbar-part p-text-right">{template?.()}</div>;
};
