import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ScrollPanel} from 'primereact/scrollpanel';
import {
  FreightOrder,
  FreightOrderAggregate,
  Stop,
  StopAggregate,
  Location,
  Run,
  Task,
  TaskType,
  RunPatch,
  Vehicle,
  FreightStage,
  QueryParameter,
} from 'two-core';
import AppContext from '../../context/AppContext';
import {ProgressSpinner} from 'primereact/progressspinner';
import StopsService from '../../services/StopsService';
import FreightOrdersService from '../../services/FreightOrdersService';
import {Button} from 'primereact/button';
import {Card} from 'primereact/card';
import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import './AddOrdersToRunComponent.scss';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import LocationsService from '../../services/LocationsService';
import RunsService from '../../services/RunsService';
import {AuthService, TwoToast} from 'two-app-ui';
import VehiclesService from '../../services/VehiclesServcie';
import {InputText} from 'primereact/inputtext';
import {faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import config from '../../config/config';
import {AppToolbar} from '../AppFrame/AppToolbar/AppToolbar';

interface RouteProps {
  runId: string;
  stopId: string;
}

interface State {
  loading: boolean;
  loadingFreightOrders: boolean;
  loadingMoreOrders: boolean;
  saving: boolean;
  stop?: Stop;
  freightOrders: FreightOrder[];
  selectedFinalDestinationsMap: Map<string, Location | undefined>;
  warehouseLocations: Location[];
  finalDestinationsMap: Map<number, Location>;
  filters: {
    searchValue: string;
  };
  vehicle?: Vehicle;
  pagination: {
    pageSize: number;
    offset: number;
  };
  freightOrdersCount: number;
}

class AddOrdersToRunComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  typingTimer?: NodeJS.Timeout;
  stopsService?: StopsService;
  runsService?: RunsService;
  freightOrdersService?: FreightOrdersService;
  twoToast?: TwoToast;
  authService?: AuthService;
  locationsService?: LocationsService;
  vehicleService?: VehiclesService;
  appToolbarRef?: React.RefObject<AppToolbar>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loading: true,
      loadingFreightOrders: false,
      loadingMoreOrders: false,
      saving: false,
      freightOrders: [],
      selectedFinalDestinationsMap: new Map<string, Location | undefined>(),
      finalDestinationsMap: new Map<number, Location>(),
      warehouseLocations: [],
      filters: {
        searchValue: '',
      },
      freightOrdersCount: 0,
      pagination: {
        pageSize: 50,
        offset: 0,
      },
    };

    this.loadData = this.loadData.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onReturn = this.onReturn.bind(this);
    this.validate = this.validate.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onTimeout = this.onTimeout.bind(this);
    this.onLoadMoreClick = this.onLoadMoreClick.bind(this);
    this.initAppToolbar = this.initAppToolbar.bind(this);
    this.appToolbarLeftTemplate = this.appToolbarLeftTemplate.bind(this);
    this.appToolbarRightTemplate = this.appToolbarRightTemplate.bind(this);
  }

  async componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.twoToast = this.context.twoToast;
    this.authService = this.context.authService;
    this.locationsService = this.context.locationsService;
    this.runsService = this.context.runsService;
    this.vehicleService = this.context.vehiclesService;
    this.appToolbarRef = this.context.appToolbarRef;
    this.loadData();
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    const {match} = this.props;
    const {filters, pagination} = this.state;
    const stopId = match.params.stopId;
    this.setState(() => ({loading: true}));
    const stop = await this.loadStop(stopId);
    let freightOrders: FreightOrder[] = [];
    let freightOrdersCount = 0;
    let warehouseLocations: Location[] = [];
    const finalDestinationsMap = new Map<number, Location>();
    const orderFinalDestinationMap = new Map<string, Location>();
    const vehicleId = localStorage.getItem('vehicleId');
    const vehicle = await this.loadVehicle(Number(vehicleId));
    if (stop && vehicle) {
      const freightOrdersResponse = await this.loadFreightOrders(
        stop.location_id,
        stop.stop_location!.state_id,
        pagination,
        filters
      );
      freightOrders = freightOrdersResponse?.freightOrders ?? [];
      freightOrdersCount = freightOrdersResponse?.freightOrdersCount ?? 0;
      warehouseLocations = (await this.loadLocations([], true, vehicle.state_id)) ?? [];
      const finalDestinationIds = freightOrders.map(order => order.final_destination_id).filter(id => id) as number[];
      if (finalDestinationIds.length) {
        const finalDestinationLocations =
          (await this.loadLocations(finalDestinationIds, false, vehicle.state_id)) ?? [];
        for (const finalDestinationLocation of finalDestinationLocations) {
          finalDestinationsMap.set(finalDestinationLocation.id!, finalDestinationLocation);
        }
      }
      this.initAppToolbar(stop);
    }
    this.setState({
      loading: false,
      stop,
      freightOrders,
      warehouseLocations,
      finalDestinationsMap,
      selectedFinalDestinationsMap: orderFinalDestinationMap,
      freightOrdersCount,
      vehicle,
    });
  }

  async loadStop(id: string) {
    const filters: string[] = [
      JSON.stringify({
        field: 'id',
        value: id,
      }),
    ];
    const aggregate: StopAggregate[] = ['proof', 'tasks', 'stop_location', 'run'];
    return this.stopsService
      ?.getStops({filters, aggregate})
      .then(data => {
        const stop = (data?.records as Stop[])[0];
        return stop;
      })
      .catch(error => {
        this.twoToast?.showError('Failed loading stop, please refresh and try again.');
        console.error(error);
        return undefined;
      });
  }

  async loadFreightOrders(
    locationId: number,
    stateId: string,
    pagination: {pageSize: number; offset: number},
    filters?: {searchValue?: string}
  ) {
    const excludedFreightStage: FreightStage = 'Delivered';
    const queryFilters = [
      JSON.stringify({
        field: 'current_location_id',
        value: locationId,
      }),
      JSON.stringify({
        field: `route->'${stateId}'->>'stage'`,
        value: excludedFreightStage,
        condition: '<>',
      }),
    ];
    if (filters?.searchValue) {
      queryFilters.push(
        JSON.stringify({
          orConditions: [
            {
              field: 'id',
              condition: 'iLike',
              value: filters.searchValue,
            },
            {
              field: 'order.reference',
              condition: 'iLike',
              value: filters.searchValue,
            },
            {
              field: 'final_destination_location.name',
              condition: 'iLike',
              value: filters.searchValue,
              tempTable: true,
            },
          ],
        })
      );
    }

    const orderBys: string[] = [
      JSON.stringify({
        field: 'final_destination_location.name',
        direction: 'ASC',
        tempTable: true,
      }),
      JSON.stringify({
        field: 'order.reference',
        direction: 'ASC',
      }),
    ];
    const aggregate: FreightOrderAggregate[] = [
      'order',
      'factory_order',
      'shipment_items',
      'tasks',
      'stops',
      'runs',
      'final_destination',
    ];

    const queryParams: QueryParameter = {
      filters: queryFilters,
      orderBys,
      aggregate,
      page_size: pagination.pageSize,
      offset: pagination.offset,
    };
    this.appToolbarRef?.current?.setLeftTemplate(() => this.appToolbarLeftTemplate(true));
    this.appToolbarRef?.current?.setRightTemplate(() => this.appToolbarRightTemplate(false, true));
    return this.freightOrdersService
      ?.getFreightOrders(queryParams)
      .then(data => {
        const freightOrders = (data?.records as FreightOrder[]) ?? [];
        const filteredFreightOrders = freightOrders.filter(
          freightOrder => freightOrder.runs?.length === 0 || freightOrder.runs?.every(run => run.stage === 'Done')
        );
        return {freightOrders: filteredFreightOrders, freightOrdersCount: data?.total_records ?? 0};
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, orders load failed, please try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => {
        this.setState({loadingFreightOrders: false});
        this.appToolbarRef?.current?.setLeftTemplate(this.appToolbarLeftTemplate);
        this.appToolbarRef?.current?.setRightTemplate(this.appToolbarRightTemplate);
      });
  }

  async loadRun(runId: number) {
    const filters = [
      JSON.stringify({
        field: 'id',
        value: runId,
      }),
    ];

    return this.runsService
      ?.getRuns({filters, aggregate: true})
      .then(data => {
        return ((data?.records ?? []) as Run[])?.[0];
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, run load failed, please try again.');
        console.error(error);
        return undefined;
      });
  }

  async loadVehicle(vehicleId: number) {
    const filters = [
      JSON.stringify({
        field: 'id',
        value: vehicleId,
      }),
    ];

    return this.vehicleService
      ?.getVehicles({filters, aggregate: true})
      .then(data => {
        return ((data?.records ?? []) as Vehicle[])?.[0];
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, vehicle load failed, please try again.');
        console.error(error);
        return undefined;
      });
  }

  async loadLocations(ids: number[], warehousesOnly: boolean, stateId: string) {
    const filters: string[] = [];
    const orderBys: string[] = [
      JSON.stringify({
        field: 'name',
        direction: 'ASC',
      }),
    ];
    if (ids.length) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: ids,
          condition: 'in',
        })
      );
    }

    if (warehousesOnly) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: 'warehouse',
        }),
        JSON.stringify({
          field: 'state_id',
          value: stateId,
        })
      );
    }

    return this.locationsService
      ?.getLocations({filters, orderBys})
      .then(data => {
        return data?.records as Location[];
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, locations load failed, please try again.');
        console.error(error);
        return undefined;
      });
  }

  initAppToolbar(stop: Stop) {
    const title = `Add orders to run ${stop.run?.name}`;
    this.appToolbarRef?.current?.initAppToolbar({
      title,
      leftTemplate: this.appToolbarLeftTemplate,
      rightTemplate: this.appToolbarRightTemplate,
    });
  }

  async onLoadMoreClick() {
    const {pagination, filters, vehicle, stop, finalDestinationsMap, freightOrders} = this.state;
    this.setState({loadingMoreOrders: true});
    const offset = pagination.offset + pagination.pageSize;
    const newPagination = {...pagination, offset};
    const freightOrdersResponse = await this.loadFreightOrders(
      stop!.location_id!,
      stop!.stop_location!.state_id,
      newPagination,
      filters
    );
    if (freightOrdersResponse) {
      const newFreightOrders = freightOrdersResponse.freightOrders;
      const newFinalDestinationIds = newFreightOrders
        .map(order => order.final_destination_id)
        .filter(id => id! && !finalDestinationsMap.has(id)) as number[];
      const newFinalDestinationsMap = new Map(finalDestinationsMap);
      if (newFinalDestinationIds.length) {
        const newFinalDestinationLocations =
          (await this.loadLocations(newFinalDestinationIds, false, vehicle!.state_id)) ?? [];
        for (const newFinalDestinationLocation of newFinalDestinationLocations) {
          newFinalDestinationsMap.set(newFinalDestinationLocation.id!, newFinalDestinationLocation);
        }
      }
      this.setState({
        pagination: newPagination,
        freightOrders: [...freightOrders, ...newFreightOrders],
        finalDestinationsMap: newFinalDestinationsMap,
        loadingMoreOrders: false,
      });
      return;
    }
    this.setState({loadingMoreOrders: false});
  }

  async onSave() {
    const {stop, freightOrders, selectedFinalDestinationsMap} = this.state;
    const selectedOrders = freightOrders.filter(order => selectedFinalDestinationsMap.has(order.id!));
    if (!this.validate(stop!.stop_location!, selectedFinalDestinationsMap)) {
      return;
    }
    const pickupStop = {...stop, tasks: stop?.tasks ?? []} as Stop;
    let pickupLastTaskLineUp = pickupStop.tasks!.reduce(
      (accumulator, currentTask) => (accumulator > currentTask.line_up ? accumulator : currentTask.line_up),
      0
    );
    this.setState(() => ({saving: true}));
    this.appToolbarRef?.current?.setLeftTemplate(() => this.appToolbarLeftTemplate(true));
    this.appToolbarRef?.current?.setRightTemplate(() => this.appToolbarRightTemplate(true));

    const run = await this.loadRun(stop!.run_id);
    if (!run) {
      this.twoToast?.showError('Sorry, Orders assign failed, please try again.');
      this.setState({loading: false});
      this.appToolbarRef?.current?.setLeftTemplate(this.appToolbarLeftTemplate);
      this.appToolbarRef?.current?.setRightTemplate(this.appToolbarRightTemplate);
      return;
    }

    // create stops map and set last line up number
    const stopsMap = new Map<number, Stop>(); // location_id => Stop
    let lastLineUp = 0;
    for (const stop of run.stops ?? []) {
      if (!stop) {
        continue;
      }
      stopsMap.set(stop.location_id, {...stop, tasks: run.tasks?.filter(task => task.stop_id === stop.id) ?? []});
      if (lastLineUp < stop.line_up) {
        lastLineUp = stop.line_up;
      }
    }

    // create new tasks in stops
    for (const order of selectedOrders) {
      // new task for pickup
      const newPickupTask = this.createTaskEntity(++pickupLastTaskLineUp, stop!, order, 'pickup');
      pickupStop.tasks!.push(newPickupTask);

      // new task for destination
      const selectedDestination = selectedFinalDestinationsMap.get(order.id!)!;
      let destinationStop = stopsMap.get(selectedDestination.id!);
      if (!destinationStop) {
        destinationStop = this.createStopEntity(++lastLineUp, run.id!, selectedDestination.id!);
        stopsMap.set(selectedDestination.id!, destinationStop);
      }
      let destinationLastTaskLineUp = destinationStop.tasks!.reduce(
        (accumulator, currentTask) => (accumulator > currentTask.line_up ? accumulator : currentTask.line_up),
        0
      );
      const newDestinationTask = this.createTaskEntity(++destinationLastTaskLineUp, destinationStop, order, 'drop');
      destinationStop.tasks!.push(newDestinationTask);
    }

    // add pickup stop to stops map
    stopsMap.set(pickupStop.location_id!, pickupStop);

    //save the changes
    const updatedRun: RunPatch = {stops: Array.from(stopsMap.values())};
    this.runsService
      ?.updateRun(run.id!, updatedRun)
      .then(() => {
        this.twoToast?.showSuccess('Orders assigned successfully');
        this.onReturn();
      })
      .catch(() => {
        this.twoToast?.showError('Sorry, Orders assign failed, please try again.');
      })
      .finally(() => {
        this.setState(() => ({saving: false}));
        this.appToolbarRef?.current?.setLeftTemplate(this.appToolbarLeftTemplate);
        this.appToolbarRef?.current?.setRightTemplate(this.appToolbarRightTemplate);
      });
  }

  validate(currentLocation: Location, selectedFinalDestinationsMap: Map<string, Location | undefined>) {
    const errorOrderIds: string[] = [];
    for (const [orderId, selectedLocation] of Array.from(selectedFinalDestinationsMap.entries())) {
      if (selectedLocation?.id === currentLocation.id) {
        errorOrderIds.push(orderId);
      }
    }
    if (errorOrderIds.length) {
      this.twoToast?.showError(`Order(s) ${errorOrderIds.join(', ')} cannot be delivered to the current location`);
      return false;
    }
    return true;
  }

  onCancel() {
    this.onReturn();
  }

  createStopEntity(lineUp: number, runId: number, locationId: number) {
    const stop: Stop = {
      stage: 'Planned',
      line_up: lineUp,
      run_id: runId,
      location_id: locationId,
      tasks: [],
    };
    return stop;
  }

  createTaskEntity(lineUp: number, stop: Stop, order: FreightOrder, type: TaskType) {
    if (type === 'drop') {
      if (order.final_destination_id === stop.location_id) {
        type = 'final-drop';
      }
    }
    const task: Task = {
      line_up: lineUp,
      stop_id: stop.id!,
      freight_order_id: order.id ?? '',
      type: type,
    };
    return task;
  }

  onReturn() {
    const {location, history} = this.props;
    const path = location?.pathname;
    const runId = path.substring(path.indexOf('/run/') + 5, path.lastIndexOf('/stop/'));
    history.push(`/run/${runId}`);
  }

  onOrderSwitch(order: FreightOrder, value: boolean) {
    this.setState(state => {
      const {selectedFinalDestinationsMap, finalDestinationsMap} = state;
      if (value) {
        selectedFinalDestinationsMap.set(order.id!, finalDestinationsMap.get(order.final_destination_id ?? 0));
      } else {
        selectedFinalDestinationsMap.delete(order.id!);
      }
      return {selectedFinalDestinationsMap: new Map(selectedFinalDestinationsMap)};
    });
  }

  onLocationSelect(orderId: string, location: Location) {
    this.setState(state => {
      const {selectedFinalDestinationsMap} = state;
      selectedFinalDestinationsMap.set(orderId, location);
      return {selectedFinalDestinationsMap: new Map(selectedFinalDestinationsMap)};
    });
  }

  onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          searchValue: event.target.value,
        },
      },
      () => {
        if (this.typingTimer) {
          clearTimeout(this.typingTimer);
        }
        this.typingTimer = setTimeout(this.onTimeout, config().stopTypingDetection);
      }
    );
  }

  async onTimeout() {
    const {stop, filters} = this.state;
    const newPagination = {pageSize: 50, offset: 0};
    const freightOrdersResponse = await this.loadFreightOrders(
      stop!.location_id,
      stop!.stop_location!.state_id,
      newPagination,
      filters
    );
    if (!freightOrdersResponse) {
      return;
    }
    this.setState({
      freightOrders: freightOrdersResponse.freightOrders ?? [],
      selectedFinalDestinationsMap: new Map<string, Location | undefined>(),
      pagination: newPagination,
    });
  }

  appToolbarLeftTemplate(disabled?: boolean) {
    return (
      <Button
        className="p-button p-py-1 p-px-3 p-button-warning"
        label="Cancel"
        onClick={this.onCancel}
        disabled={disabled}
      />
    );
  }

  appToolbarRightTemplate(saving?: boolean, disabled?: boolean) {
    return (
      <Button
        className="p-button p-py-1 p-px-3 p-button-success"
        label="Add Orders"
        onClick={this.onSave}
        loading={saving}
        disabled={disabled}
      />
    );
  }

  renderFreightOrdersContent(
    freightOrders: FreightOrder[],
    selectedFinalDestinationsMap: Map<string, Location | undefined>,
    finalDestinationsMap: Map<number, Location>,
    warehouseLocations: Location[],
    loadingFreightOrders: boolean
  ) {
    if (loadingFreightOrders) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!freightOrders.length) {
      return (
        <div className="p-d-flex p-ai-center p-jc-center p-m-5">
          <strong>No orders available</strong>
        </div>
      );
    }

    return freightOrders.map((freightOrder, index) => {
      const order = freightOrder?.order;
      const factoryOrder = freightOrder?.factory_order;
      const orderShipmentItems = freightOrder?.shipment_items?.filter(si => si && si.order_id === order?.id) ?? [];
      const boxesType =
        factoryOrder?.product_line === 'Colourvue' ? 'cv' : factoryOrder?.product_line === 'Shadesol' ? 'ss' : 'sh';

      let finalDestination: Location | undefined;
      if (selectedFinalDestinationsMap.has(freightOrder.id!)) {
        finalDestination = selectedFinalDestinationsMap.get(freightOrder.id!);
      } else if (freightOrder.final_destination_id) {
        finalDestination = finalDestinationsMap.get(freightOrder.final_destination_id);
      }

      let destinations: Location[] = [];
      if (freightOrder.final_destination_id) {
        if (finalDestinationsMap.has(+freightOrder.final_destination_id)) {
          destinations = [finalDestinationsMap.get(freightOrder.final_destination_id)!, ...warehouseLocations];
        }
      } else {
        destinations = warehouseLocations;
      }
      destinations.sort((a, b) => {
        if (b.id === finalDestination?.id) {
          return 1;
        }
        if (a.id === finalDestination?.id) {
          return -1;
        }
        return a?.name.localeCompare(b?.name ?? '');
      });

      return (
        <Card key={`freight-order-${freightOrder?.id}`} className={'p-mt-3'}>
          <div className="p-d-flex p-ai-center p-jc-between p-px-3 p-py-2">
            <div className="p-d-flex p-p-0 p-col-7 p-flex-wrap">
              <span className={'p-pr-2'}>{freightOrder?.id ?? ''}</span>
              <span className={'p-pr-2 account-bold'}>{freightOrder?.owner_company?.account_number ?? ''}</span>
              <span className={'p-pr-2'}>{freightOrder?.order?.reference ?? ''}</span>
            </div>
            <div className="p-d-flex p-p-0 p-col-1">
              <span>{`${orderShipmentItems.length} ${boxesType}`}</span>
            </div>
            <div className="p-d-flex p-p-0 p-col-3 p-ai-center">
              {selectedFinalDestinationsMap.has(freightOrder.id!) ? (
                <Dropdown
                  style={{width: '100%'}}
                  options={destinations}
                  optionGroupChildren="type"
                  value={finalDestination}
                  optionLabel="name"
                  onChange={e => this.onLocationSelect(freightOrder.id!, e.value)}
                />
              ) : (
                <span>{finalDestination?.name}</span>
              )}
            </div>
            <div className="p-d-flex p-p-0 p-col-1 p-jc-end p-ai-center p-my-3">
              <InputSwitch
                className="p-mr-2"
                checked={selectedFinalDestinationsMap.has(freightOrder.id!)}
                onChange={e => this.onOrderSwitch(freightOrder, e.value)}
              />
            </div>
          </div>
        </Card>
      );
    });
  }

  render() {
    const {
      loading,
      loadingFreightOrders,
      loadingMoreOrders,
      saving,
      freightOrders,
      selectedFinalDestinationsMap,
      warehouseLocations,
      finalDestinationsMap,
      stop,
      filters,
      pagination,
      freightOrdersCount,
    } = this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!stop) {
      return <></>;
    }

    const panelHeader = (options: PanelHeaderTemplateOptions) => {
      const className = `${options.className}`;
      const titleClassName = `${options.titleClassName}`;
      let boxCount = 0;
      for (const freightOrder of freightOrders) {
        if (selectedFinalDestinationsMap.has(freightOrder.id!)) {
          boxCount += freightOrder.shipment_items?.length ?? 0;
        }
      }

      return (
        <>
          <div className={className}>
            <div className="p-d-flex p-ai-center p-jc-between w-100">
              <div className="p-mr-2">
                <span className={titleClassName + ' p-as-center'}>Orders present at {stop.stop_location?.name}</span>
              </div>
              <div>
                {!!freightOrders.length && (
                  <span className={titleClassName + ' p-as-center'}>Adding {boxCount} boxes</span>
                )}{' '}
              </div>
            </div>
          </div>
        </>
      );
    };

    return (
      <ScrollPanel id="add_orders_to_run_component">
        <Panel headerTemplate={panelHeader}>
          <div className="orders-search">
            <div className="p-inputgroup">
              <InputText
                name="searchValue"
                className="p-inputtext-sm"
                placeholder="Search by id, reference or destination name"
                value={filters.searchValue}
                onChange={this.onSearchChange}
              />
              <Button className="p-button-sm" icon={<FontAwesomeIcon icon={faMagnifyingGlass} />} />
            </div>
          </div>
          {this.renderFreightOrdersContent(
            freightOrders,
            selectedFinalDestinationsMap,
            finalDestinationsMap,
            warehouseLocations,
            loadingFreightOrders
          )}
          {pagination.offset + pagination.pageSize < freightOrdersCount && (
            <div className="p-d-flex p-jc-center p-mt-3">
              <Button
                label="Load More ..."
                className="p-button-lg"
                onClick={this.onLoadMoreClick}
                loading={loadingMoreOrders}
              />
            </div>
          )}
        </Panel>
      </ScrollPanel>
    );
  }
}

export default withRouter(AddOrdersToRunComponent);
