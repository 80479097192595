import React from 'react';

interface Props {
  title: string;
}
export const CenterPart = ({title}: Props) => {
  return (
    <div className="app-toolbar-part app-toolbar-center-part p-text-center">
      <strong className="">{title}</strong>
    </div>
  );
};
