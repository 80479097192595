export const config = () => {
  const freightService = process.env.REACT_APP_FREIGHT_SERVICE;
  const userService = process.env.REACT_APP_USER_SERVICE;
  const orderService = process.env.REACT_APP_ORDER_SERVICE;
  const tleService = process.env.REACT_APP_TLE_SERVICE;
  return {
    cognito: {
      poolid: process.env.REACT_APP_COGNITO_POOL_ID,
      clientid: process.env.REACT_APP_ID,
    },
    endpoints: {
      users: userService + 'users',
      schedules: freightService + 'schedule-entries',
      stops: freightService + 'stops',
      runs: freightService + 'runs',
      tasks: freightService + 'tasks',
      vehicles: freightService + 'vehicles',
      freightOrders: freightService + 'freight-orders',
      freightProofs: freightService + 'freight-proof',
      orders: orderService + 'orders',
      tles: tleService + 'tles',
      locations: freightService + 'locations',
    },
    aws: {
      proofBucket: process.env.REACT_APP_FILES_PROOF_BUCKET_NAME,
    },
    stopTypingDetection: 1000,
  };
};
export default config;
