import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {AppContext} from '../../context/AppContext';
import ScheduleComponent from '../Schedule/ScheduleComponent';
import RunComponent from '../Run/RunComponent';
import './AppFrame.scss';
import OrderComponent from '../Order/OrderComponent';
import Login from '../auth/Login';
import {Subscription} from 'rxjs';
import {VehicleComponent} from '../Vehicle/VehicleComponent';
import TaskComponent from '../Task/TaskComponent';
import AddOrdersToRunComponent from '../Run/AddOrdersToRunComponent';
import ProofComponent from '../Proof/ProofComponent';
import {AuthService, MessageService} from 'two-app-ui';
import {AppToolbar} from './AppToolbar/AppToolbar';
import ReOrderStopsComponent from '../Run/ReOrderStopsComponent';

interface Props {
  appToolbarRef: React.RefObject<AppToolbar>;
}
interface State {
  isAuthenticated: boolean;
  loading: boolean;
}

export class AppFrame extends React.Component<Props, State> {
  static contextType = AppContext;

  authService: AuthService;
  subscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);

    this.authService = new AuthService();

    this.state = {
      isAuthenticated: false,
      loading: false,
    };
  }

  async componentDidMount() {
    this.authService = this.context.authService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      console.log(message);
      this.onLoggedIn();
    });

    this.onLoggedIn();
  }

  async onLoggedIn() {
    await this.authService
      .isSessionValid()
      .then(async isAuthenticated => {
        await this.setState({
          isAuthenticated: isAuthenticated,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          isAuthenticated: false,
          loading: false,
        });
        console.error(error);
      });
  }

  render() {
    const {appToolbarRef} = this.props;
    return (
      <div id="layout-wrapper" className="p-d-flex p-flex-column">
        <Router>
          {this.state.isAuthenticated ? (
            <React.Fragment>
              <div id="topbar" className="layout-topbar p-shadow-2 p-d-flex p-flex-column">
                <AppToolbar ref={appToolbarRef} />
              </div>
              <div id="content" className="p-d-flex p-flex-column p-p-4">
                <Switch>
                  <Route path="/schedule">
                    <ScheduleComponent />
                  </Route>
                  <Route path="/run/:runId/order/:id">
                    <OrderComponent />
                  </Route>
                  <Route path="/run/:runId/re-order-stops">
                    <ReOrderStopsComponent />
                  </Route>
                  <Route path="/run/:runId/task/:id">
                    <TaskComponent />
                  </Route>
                  <Route path="/run/:runId/stop/:stopId/add-orders-to-run">
                    <AddOrdersToRunComponent />
                  </Route>
                  <Route path="/run/:runId/stop/:stopId/proof/">
                    <ProofComponent />
                  </Route>
                  <Route path="/run/:id">
                    <RunComponent />
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/">
                    <VehicleComponent />
                  </Route>
                </Switch>
              </div>
            </React.Fragment>
          ) : (
            <div id="content">
              <Login />
            </div>
          )}
        </Router>
      </div>
    );
  }
}

export default AppFrame;
