import config from '../config/config';
import {ApiListResponse, ApiResponse, FreightOrder, FreightOrderPatch, QueryParameter} from 'two-core';
import {ApiService, AuthService} from 'two-app-ui';

class FreightOrdersService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.freightOrders ?? '';
  }

  async getFreightOrders(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }

    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateFreightOrder(id: string, data: FreightOrderPatch): Promise<FreightOrder> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as FreightOrder);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default FreightOrdersService;
