import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ScrollPanel} from 'primereact/scrollpanel';
import {OrdersPanel} from './Sections/OrdersPanel';
import {FreightOrder, FreightOrderAggregate, FreightProof, FreightProofFile, Stop, StopAggregate, Task} from 'two-core';
import {PhotosPanel} from './Sections/PhotosPanel';
import {SignaturePanel} from './Sections/SignaturePanel';
import {NotePanel} from './Sections/NotePanel';
import SignatureCanvas from 'react-signature-canvas';
import AppContext from '../../context/AppContext';
import {ProgressSpinner} from 'primereact/progressspinner';
import './ProofComponent.scss';
import StopsService from '../../services/StopsService';
import {AuthService, TwoToast} from 'two-app-ui';
import FreightOrdersService from '../../services/FreightOrdersService';
import {Button} from 'primereact/button';
import FreightProofsService from '../../services/FreightProofsService';
import {AppToolbar} from '../AppFrame/AppToolbar/AppToolbar';

interface RouteProps {
  runId: string;
  stopId: string;
  proofId: string;
}

interface State {
  loading: boolean;
  saving: boolean;
  collapsePhotos: boolean;
  collapseSignature: boolean;
  collapseNote: boolean;
  stop?: Stop;
  proofPatch: Partial<FreightProof>;
  ordersMap: Map<string, FreightOrder>;
  signatureFile?: File;
  newFiles?: File[];
  photoUrlsToDelete: string[];
}

class ProofComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  stopsService?: StopsService;
  freightOrdersService?: FreightOrdersService;
  freightProofsService?: FreightProofsService;
  twoToast?: TwoToast;
  authService?: AuthService;
  appToolbarRef?: React.RefObject<AppToolbar>;

  signatureCanvasRef: React.RefObject<SignatureCanvas>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      collapsePhotos: false,
      collapseSignature: true,
      collapseNote: true,
      proofPatch: {},
      ordersMap: new Map<string, FreightOrder>(),
      photoUrlsToDelete: [],
    };

    this.signatureCanvasRef = React.createRef();

    this.onTaskSwitch = this.onTaskSwitch.bind(this);
    this.onTempPhotoDelete = this.onTempPhotoDelete.bind(this);
    this.onProofChange = this.onProofChange.bind(this);
    this.onFilesSelect = this.onFilesSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onReturn = this.onReturn.bind(this);
    this.onPhotosVisibilityToggle = this.onPhotosVisibilityToggle.bind(this);
    this.onSignatureVisibilityToggle = this.onSignatureVisibilityToggle.bind(this);
    this.onNoteVisibilityToggle = this.onNoteVisibilityToggle.bind(this);
    this.initAppToolbar = this.initAppToolbar.bind(this);
    this.appToolbarLeftTemplate = this.appToolbarLeftTemplate.bind(this);
    this.appToolbarRightTemplate = this.appToolbarRightTemplate.bind(this);
  }

  async componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.freightProofsService = this.context.freightProofsService;
    this.twoToast = this.context.twoToast;
    this.authService = this.context.authService;
    this.appToolbarRef = this.context.appToolbarRef;

    this.loadData();
  }
  async loadData() {
    const {match} = this.props;
    const stopId = match.params.stopId;
    this.setState(() => ({loading: true}));
    const stop = await this.loadStop(stopId);
    let ordersMap = new Map<string, FreightOrder>();
    let collapsePhotos = false;
    let collapseSignature = true;
    let collapseNote = true;
    let includePhotos = true;
    let includeSignature = false;
    let includeNote = false;
    if (stop) {
      const ordersIds = stop.tasks?.map(task => task.freight_order_id!) ?? [];
      ordersMap = (await this.loadFreightOrders(ordersIds)) ?? new Map<string, FreightOrder>();
      const proof = stop.proof;
      if (proof) {
        if (!proof.photos?.length) {
          collapsePhotos = true;
          includePhotos = false;
        }
        if (proof.signee_name?.length) {
          collapseSignature = false;
          includeSignature = true;
        }
        if (proof.note?.length) {
          collapseNote = false;
          includeNote = true;
        }
      }
      this.initAppToolbar(stop, proof);
    }
    this.setState({
      loading: false,
      stop,
      ordersMap,
      collapseSignature,
      collapseNote,
      collapsePhotos,
    });
  }

  initAppToolbar(stop: Stop, proof?: FreightProof) {
    let title: string;
    if (proof) {
      title = `Editing proof for ${stop.stop_location?.name}`;
    } else {
      title = `New proof for ${stop.stop_location?.name}`;
    }
    this.appToolbarRef?.current?.initAppToolbar({
      title,
      leftTemplate: this.appToolbarLeftTemplate,
      rightTemplate: this.appToolbarRightTemplate,
    });
  }

  async loadStop(id: string) {
    const filters: string[] = [
      JSON.stringify({
        field: 'id',
        value: id,
      }),
    ];
    const aggregate: StopAggregate[] = ['proof', 'tasks', 'stop_location'];
    return this.stopsService
      ?.getStops({filters, aggregate})
      .then(data => {
        const stop = (data?.records as Stop[])[0];
        return stop;
      })
      .catch(error => {
        this.twoToast?.showError('Failed loading stop, please refresh and try again.');
        console.error(error);
        return undefined;
      });
  }

  async loadFreightOrders(ids: string[]) {
    const filters = [
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      }),
    ];
    const aggregate: FreightOrderAggregate[] = ['order', 'factory_order', 'shipment_items'];

    return this.freightOrdersService
      ?.getFreightOrders({filters, aggregate})
      .then(data => {
        const dataRecords = (data?.records as FreightOrder[]) ?? [];
        const freightOrdersMap = new Map<string, FreightOrder>();
        for (const freightOrder of dataRecords as FreightOrder[]) {
          freightOrdersMap.set(freightOrder.id!, freightOrder);
        }
        return freightOrdersMap;
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, orders load failed, please try again.');
        console.error(error);
        return undefined;
      });
  }

  onSaveClick() {
    const {stop, proofPatch, newFiles, photoUrlsToDelete} = this.state;
    const proof = stop?.proof;
    if (!this.validate(proofPatch, proof, newFiles, this.signatureCanvasRef.current)) {
      return;
    }

    this.onSave(proofPatch, stop, proof, newFiles, photoUrlsToDelete);
  }

  async onSave(
    proofPatch: Partial<FreightProof>,
    stop?: Stop,
    proof?: FreightProof,
    newFiles?: File[],
    photoUrlsToDelete?: string[]
  ) {
    this.setState(() => ({saving: true}));
    this.appToolbarRef?.current?.setLeftTemplate(() => this.appToolbarLeftTemplate(true));
    this.appToolbarRef?.current?.setRightTemplate(() => this.appToolbarRightTemplate(true));
    if (proof) {
      const newProofFiles = await this.uploadPhotos(proof.id, newFiles);
      const updatedPatch = {...proofPatch};
      if (newProofFiles?.length) {
        updatedPatch.photos = [...(proof.photos ?? []), ...newProofFiles];
      }
      if (photoUrlsToDelete && photoUrlsToDelete.length) {
        await this.deletePhotos(proof.id, photoUrlsToDelete);
        if (updatedPatch.photos?.length) {
          updatedPatch.photos = updatedPatch.photos.filter(photo => !photoUrlsToDelete.includes(photo.url));
        } else {
          updatedPatch.photos = (proof.photos ?? []).filter(photo => !photoUrlsToDelete.includes(photo.url));
        }
      }
      const newSignatureUrl = await this.uploadSignature(proof.id, this.signatureCanvasRef.current);
      if (newSignatureUrl) {
        updatedPatch.signature_picture = newSignatureUrl;
      }
      const updatedProof = await this.updateProof(proof.id, updatedPatch);
      if (updatedProof) {
        this.onReturn();
      }
    } else {
      const orderIds = proofPatch.order_ids ?? stop?.tasks?.map(task => task.freight_order_id!);
      const createdProof = await this.createProof({
        ...proofPatch,
        order_ids: orderIds,
        taken_at: new Date(),
        location_id: stop!.location_id,
        stop_id: stop!.id,
      });
      if (createdProof) {
        const newProofFiles = await this.uploadPhotos(createdProof.id, newFiles);
        const updatedPatch: Partial<FreightProof> = {};
        if (newProofFiles?.length) {
          updatedPatch.photos = newProofFiles;
        }
        const newSignatureUrl = await this.uploadSignature(createdProof.id, this.signatureCanvasRef.current);
        if (newSignatureUrl) {
          updatedPatch.signature_picture = newSignatureUrl;
        }
        if (updatedPatch.photos || updatedPatch.signature_picture) {
          const updatedProof = await this.updateProof(createdProof.id, updatedPatch);
          if (updatedProof) {
            this.onReturn();
          }
        } else {
          this.onReturn();
        }
      }
    }
    this.appToolbarRef?.current?.setLeftTemplate(() => this.appToolbarLeftTemplate(false));
    this.appToolbarRef?.current?.setRightTemplate(() => this.appToolbarRightTemplate(false));
    this.setState(() => ({saving: false}));
  }

  validate(
    proofPatch: Partial<FreightProof>,
    proof?: Partial<FreightProof>,
    newFiles?: File[],
    currSignatureCanvas?: SignatureCanvas | null
  ): boolean {
    const errors: string[] = [];

    if (proofPatch.order_ids && proofPatch.order_ids.length === 0) {
      errors.push('The proof must have at least one order.');
    }
    const signeeName = proofPatch.signee_name ?? proof?.signee_name;
    let signaturePictureExist = false;
    if ((currSignatureCanvas && !currSignatureCanvas.isEmpty()) || proof?.signature_picture) {
      signaturePictureExist = true;
    }
    const newPhotoUrls = newFiles?.map(file => URL.createObjectURL(file)) ?? [];
    const mergedPhotoUrls = [...newPhotoUrls, ...(proof?.photos?.map(photo => photo.url) ?? [])];
    if (!(signeeName && signaturePictureExist) && mergedPhotoUrls.length === 0) {
      errors.push('The proof must have a signature or at least one photo.');
    }
    if (errors.length) {
      const errorText = (
        <div>
          Form is invalid:
          {errors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </div>
      );
      this.twoToast?.showError(errorText);
      return false;
    }
    return true;
  }

  async updateProof(id: number, proofPatch: Partial<FreightProof>) {
    if (Object.values(proofPatch).length) {
      return this.freightProofsService!.updateFreightProof(id, proofPatch)
        .then(proof => {
          this.twoToast?.showSuccess('Freight proof updated successfully.');
          return proof;
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, freight proof update failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return;
  }

  async uploadPhotos(proofId: number, newFiles?: File[]): Promise<FreightProofFile[] | undefined> {
    if (newFiles?.length) {
      const promises = [];
      for (const newFile of newFiles) {
        promises.push(this.freightProofsService?.uploadPhoto(proofId, newFile));
      }
      return Promise.all(promises)
        .then(responses => {
          this.twoToast?.showSuccess('Photos uploaded successfully.');
          return responses.map(response => {
            const url = new URL(response!.config.url!);
            const proofFile: FreightProofFile = {url: `${url.origin}${url.pathname}`, uploaded_at: new Date()};
            return proofFile;
          });
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, photos upload failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  async deletePhotos(proofId: number, photoUrlsToDelete: string[]) {
    if (photoUrlsToDelete?.length) {
      const promises = [];
      for (const urlToDelete of photoUrlsToDelete) {
        promises.push(this.freightProofsService?.deletePhoto(proofId, urlToDelete));
      }
      return Promise.all(promises)
        .then(() => {
          this.twoToast?.showSuccess('Photos deleted successfully.');
          return true;
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, photos delete failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  async uploadSignature(proofId: number, currSignatureCanvas?: SignatureCanvas | null): Promise<string | undefined> {
    if (currSignatureCanvas && !currSignatureCanvas.isEmpty()) {
      const signatureDataUrl = currSignatureCanvas.getCanvas().toDataURL();
      const signatureFile = await this.dataUrlToFile(signatureDataUrl, 'signature.png');
      return this.freightProofsService
        ?.uploadSignature(proofId, signatureFile)
        .then(response => {
          this.twoToast?.showSuccess('Signature uploaded successfully.');
          const url = new URL(response!.config.url!);
          return `${url.origin}${url.pathname}`;
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, signature upload failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  async dataUrlToFile(url: string, filename: string) {
    const arr = url.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  createProof(proofPatch: Partial<FreightProof>) {
    return this.freightProofsService!.createFreightProof(proofPatch)
      .then(proof => {
        this.twoToast?.showSuccess('Freight proof created successfully.');
        return proof;
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, freight proof creation failed, please try again.');
        console.error('error: ' + error);
        return undefined;
      });
  }

  onTaskSwitch(task: Task) {
    this.setState(state => {
      const {proofPatch, stop} = state;
      const selectedTasks = this.getSelectedTasks(proofPatch, stop?.proof, stop?.tasks);
      const selectedOrderIds = selectedTasks?.map(selectedTask => selectedTask.freight_order_id!) ?? [];
      const newSelectedOrderIds: string[] = [];
      if (selectedOrderIds.includes(task.freight_order_id!)) {
        newSelectedOrderIds.push(...selectedOrderIds.filter(id => id !== task.freight_order_id!));
      } else {
        newSelectedOrderIds.push(...selectedOrderIds, task.freight_order_id!);
      }
      return {proofPatch: {...proofPatch, order_ids: newSelectedOrderIds}};
    });
  }

  onTempPhotoDelete(index: number) {
    this.setState(state => {
      const updatedFiles = [...state.newFiles!];
      updatedFiles.splice(index, 1);
      return {
        newFiles: updatedFiles,
      };
    });
  }

  onProofChange(partialProof: Partial<FreightProof>) {
    this.setState(state => {
      return {proofPatch: {...state.proofPatch, ...partialProof}};
    });
  }

  onFilesSelect(selectedFiles: File[]) {
    this.setState(state => {
      const renamedFiles = selectedFiles.map((file, index) => {
        const fileExtension = file.name.split('.')[1].toLowerCase();
        const newFileName = `${Date.now()}${index}.${fileExtension}`;
        return new File([file], newFileName, {type: file.type});
      });
      return {newFiles: [...(state.newFiles ?? []), ...renamedFiles]};
    });
  }

  onCancel() {
    this.onReturn();
  }

  onReturn() {
    const {location, history} = this.props;
    const path = location?.pathname;
    const runId = path.substring(path.indexOf('/run/') + 5, path.lastIndexOf('/stop/'));
    history.push(`/run/${runId}`);
  }

  onPhotosVisibilityToggle(collapse: boolean) {
    this.setState(() => ({collapsePhotos: collapse}));
  }

  onSignatureVisibilityToggle(collapse: boolean) {
    this.setState(() => ({collapseSignature: collapse}));
  }

  onNoteVisibilityToggle(collapse: boolean) {
    this.setState(() => ({collapseNote: collapse}));
  }

  getSelectedTasks(
    proofPatch: Partial<FreightProof>,
    proof: FreightProof | undefined,
    stopTasks: Task[] | undefined
  ): Task[] | undefined {
    if (proofPatch?.order_ids) {
      return stopTasks!.filter(task => proofPatch.order_ids?.includes(task.freight_order_id!));
    }
    if (proof?.order_ids) {
      return stopTasks!.filter(task => proof.order_ids?.includes(task.freight_order_id!));
    }
    return stopTasks;
  }

  appToolbarLeftTemplate(disabled?: boolean) {
    return (
      <Button
        className="p-button p-py-1 p-px-3 p-button-warning"
        label="Cancel"
        onClick={this.onCancel}
        disabled={disabled}
      />
    );
  }

  appToolbarRightTemplate(saving?: boolean) {
    return (
      <Button
        className="p-button p-py-1 p-px-3 p-button-success"
        label="Save"
        onClick={this.onSaveClick}
        loading={saving}
      />
    );
  }

  render() {
    const {loading, saving, collapsePhotos, collapseSignature, collapseNote, proofPatch, newFiles, stop, ordersMap} =
      this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!stop) {
      return <></>;
    }
    const proof = stop.proof;
    const tasks = stop.tasks ?? [];
    const selectedTasks = this.getSelectedTasks(proofPatch, proof, tasks) ?? [];
    const newPhotoUrls = newFiles?.map(file => URL.createObjectURL(file)) ?? [];
    const mergedPhotoUrls = [...newPhotoUrls, ...(proof?.photos?.map(photo => photo.url) ?? [])];
    return (
      <ScrollPanel id="proof_component">
        {!!tasks?.length && (
          <OrdersPanel
            tasks={tasks}
            selectedTasks={selectedTasks}
            disabled={saving}
            ordersMap={ordersMap}
            onTaskSwitch={this.onTaskSwitch}
          />
        )}
        <PhotosPanel
          collapsed={collapsePhotos}
          disabled={saving}
          photoUrls={mergedPhotoUrls}
          onVisibilityToggle={this.onPhotosVisibilityToggle}
          onFilesSelect={this.onFilesSelect}
          onTempPhotoDelete={this.onTempPhotoDelete}
        />
        <NotePanel
          note={proofPatch.note ?? proof?.note ?? ''}
          collapsed={collapseNote}
          disabled={saving}
          onVisibilityToggle={this.onNoteVisibilityToggle}
          onNoteChange={(value: string) => this.onProofChange({note: value})}
        />
        <SignaturePanel
          collapsed={collapseSignature}
          disabled={saving}
          name={proofPatch.signee_name ?? proof?.signee_name ?? ''}
          signatureFileUrl={proof?.signature_picture ?? ''}
          onVisibilityToggle={this.onSignatureVisibilityToggle}
          onNameChange={(value: string) => this.onProofChange({signee_name: value})}
          signatureCanvasRef={this.signatureCanvasRef}
        />
      </ScrollPanel>
    );
  }
}

export default withRouter(ProofComponent);
