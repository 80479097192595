import React from 'react';
import {AppContext} from './context/AppContext';
import SchedulesService from './services/SchedulesService';
import StopsService from './services/StopsService';
import RunsService from './services/RunsService';
import AppFrame from './components/AppFrame/AppFrame';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheck,
  faCalendarAlt,
  faSignOutAlt,
  faSignInAlt,
} from '@fortawesome/pro-light-svg-icons';
import {faArrowAltToTop, faArrowAltToBottom, faNote} from '@fortawesome/pro-solid-svg-icons';
import './scss/App.scss';
import TasksService from './services/TasksService';
import FreightOrdersService from './services/FreightOrdersService';
import VehiclesService from './services/VehiclesServcie';
import TlesService from './services/TlesService';
import OrdersService from './services/OrdersService';
import {AuthService, FileService, UsersService, TwoToast, ToastService} from 'two-app-ui';
import LocationsService from './services/LocationsService';
import FreightProofsService from './services/FreightProofsService';
import {Toast} from 'primereact/toast';
import {AppToolbar} from './components/AppFrame/AppToolbar/AppToolbar';

library.add(
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheck,
  faCalendarAlt,
  faSignOutAlt,
  faSignInAlt,
  faArrowAltToTop,
  faArrowAltToBottom,
  faNote
);

const authService = new AuthService();
const usersService = new UsersService(authService);
const schedulesService = new SchedulesService(authService);
const stopsService = new StopsService(authService);
const runsService = new RunsService(authService);
const tasksService = new TasksService(authService);
const freightOrdersService = new FreightOrdersService(authService);
const vehiclesService = new VehiclesService(authService);
const tlesService = new TlesService(authService);
const ordersService = new OrdersService(authService);
const toastService = new ToastService();
const locationsService = new LocationsService(authService);
const toastRef = React.createRef<Toast>();
const twoToast = new TwoToast(toastRef);
const freightProofsService = new FreightProofsService(authService);
const fileService = new FileService(authService);
const appToolbarRef = React.createRef<AppToolbar>();

export class App extends React.Component<{}> {
  constructor(props = {}) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          authService: authService,
          schedulesService: schedulesService,
          stopsService: stopsService,
          runsService: runsService,
          tasksService: tasksService,
          freightOrdersService: freightOrdersService,
          usersService: usersService,
          vehiclesService: vehiclesService,
          tlesService: tlesService,
          ordersService: ordersService,
          toastService: toastService,
          locationsService: locationsService,
          twoToast: twoToast,
          freightProofsService: freightProofsService,
          fileService: fileService,
          appToolbarRef: appToolbarRef,
        }}
      >
        <AppFrame appToolbarRef={appToolbarRef} />
        <Toast ref={toastRef} />
      </AppContext.Provider>
    );
  }
}

export default App;
