import React, {ReactNode} from 'react';
import AppContext from '../../context/AppContext';
import {Toast} from 'primereact/toast';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {
  FreightOrder,
  Order,
  QueryParameter,
  Run,
  Stop,
  Task,
  Location,
  MapOf,
  TaskPatch,
  StopAggregate,
} from 'two-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import StopsService from '../../services/StopsService';
import RunsService from '../../services/RunsService';
import './RunComponent.scss';
import TasksService from '../../services/TasksService';
import FreightOrdersService from '../../services/FreightOrdersService';
import {LocationType} from 'two-core/build/esm/src/location';
import TlesService from '../../services/TlesService';
import OrdersService from '../../services/OrdersService';
import LocationDetail from './LocationDetailDialog';
import {faBars, faMessageExclamation} from '@fortawesome/pro-light-svg-icons';
import {faHandHoldingBox, faLineHeight} from '@fortawesome/pro-regular-svg-icons';
import {AppMenuItem, AppMenuItemTemplate} from 'two-app-ui';
import {AppToolbar} from '../AppFrame/AppToolbar/AppToolbar';
import {MenuItem, MenuItemOptions} from 'primereact/menuitem';
import {Menu} from 'primereact/menu';
import {faCamera} from '@fortawesome/pro-regular-svg-icons';
import {ToastService} from 'two-app-ui';

interface RouteProps {
  id: string;
}

interface State {
  loading: boolean;
  run: Run | undefined;
  stops: Stop[];
  locationDetail: Location | undefined;
  showLocationDetail: boolean;
  freightOrdersMap: MapOf<FreightOrder>; //orderId: freightOrderObject
  redirectInfo?: {
    runId: number;
    orderId?: string;
    taskId?: number;
  };
  ordersDropLineUpMap: MapOf<number>; //orderId: dropStop.line_up
}

export class RunComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  stopsService?: StopsService;
  runsService?: RunsService;
  tasksService?: TasksService;
  ordersService?: OrdersService;
  freightOrdersService?: FreightOrdersService;
  tlesService?: TlesService;
  toastService?: ToastService;
  appToolbarRef?: React.RefObject<AppToolbar>;

  menuRef = React.createRef<Menu>();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.state = {
      loading: false,
      stops: [],
      locationDetail: undefined,
      showLocationDetail: false,
      freightOrdersMap: {},
      run: undefined,
      ordersDropLineUpMap: {},
    };

    this.onLocationDetailClick = this.onLocationDetailClick.bind(this);
    this.appToolbarRightTemplate = this.appToolbarRightTemplate.bind(this);
    this.initAppToolbar = this.initAppToolbar.bind(this);

    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.runsService = this.context.runsService;
    this.tasksService = this.context.tasksService;
    this.ordersService = this.context.ordersService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tlesService = this.context.tlesService;
    this.toastService = this.context.toastService;
    this.appToolbarRef = this.context.appToolbarRef;

    const id = this.props.match.params.id;
    this.loadRun(id);
    this.loadData(id);
  }

  async loadRun(id: string) {
    this.setState({loading: true});
    this.runsService
      ?.getRun(id)
      .then(data => {
        this.setState({
          run: data,
        });
        this.setState({loading: false});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      })
      .finally(() => {
        this.initAppToolbar();
      });
  }

  async loadData(runId: string) {
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run_id',
        value: runId,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'line_up',
        direction: 'ASC',
      })
    );

    const aggregate: StopAggregate[] = ['tasks', 'stop_location', 'run', 'proof'];

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
      aggregate: aggregate,
    };

    this.stopsService
      ?.getStops(params)
      .then(data => {
        const stops = (data?.records as Stop[]) ?? [];

        this.loadFreightOrders(runId);

        const ordersDropLineUpMap: MapOf<number> = {};
        for (const stop of stops) {
          if (!stop.tasks) {
            continue;
          }
          for (const task of stop.tasks) {
            if (task.type === 'drop' || task.type === 'final-drop') {
              ordersDropLineUpMap[task.freight_order_id!] = stop.line_up;
            }
          }
        }

        this.setState({
          stops: stops,
          ordersDropLineUpMap: ordersDropLineUpMap,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  async loadFreightOrders(runId: string) {
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run.id',
        value: runId,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      filters: filters,
      aggregate: true,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = ((data?.records as FreightOrder[]) ?? []).filter(se => se !== null);
        const freightOrdersMap: MapOf<FreightOrder> = {};
        for (const freightOrder of dataRecords as FreightOrder[]) {
          freightOrdersMap[freightOrder.id!] = freightOrder;
        }

        this.setState({
          freightOrdersMap: freightOrdersMap,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  async updateTask(task: TaskPatch) {
    this.setState({loading: true});

    const id = task.id;
    if (id) {
      return this.tasksService
        ?.updateTask(id.toString(), task)
        .then(() => {
          //this.moveOrderAfterTaskCompletion(task, freightOrder, userId);
          this.toastService?.showSuccess(this.toast, 'Task updated successfully.');
          this.setState({loading: false});
          this.loadData(this.props.match.params.id);
        })
        .catch(error => {
          this.toastService?.showError(
            this.toast,
            'Task update failed. Please go back to Schedule, re-open the Run and try again.'
          );
          console.error('error: ' + error);
          this.setState({loading: false});
        });
    } else {
      this.toastService?.showError(
        this.toast,
        'Uups, data is wrong. Please return to Schedule, re-open the Run and try again.'
      );
    }
  }

  //Done button click function
  setTaskCompletion(task: Task) {
    this.setState({loading: true});
    let latitude = 0;
    let longitude = 0;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
        },
        (error: GeolocationPositionError) => {
          //handle errors e.g. blocked geolocation
          console.error(error);
        }
      );
    }
    this.taskCompleted(task, longitude, latitude);
  }

  findPickupTask(orderId: string): Task | undefined {
    const {stops} = this.state;
    for (const stop of stops) {
      for (const task of stop.tasks ?? []) {
        if (task.type === 'pickup' && task.freight_order_id === orderId) {
          return task;
        }
      }
    }
    return undefined;
  }

  async taskCompleted(task: Task, longitude: number, latitude: number) {
    const freightOrder = this.state.freightOrdersMap[task.freight_order_id!];
    const taskType = task.type;

    if (freightOrder && (taskType === 'drop' || taskType === 'final-drop')) {
      const pickupTask = this.findPickupTask(freightOrder.id!);
      if (pickupTask && !pickupTask.executed_on) {
        this.toastService?.showWarn(
          this.toast,
          'The order cannot be dropped before being picked-up. Finish its pick-up task first.'
        );
        this.setState({loading: false});
        return;
      }
    }
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';

    const updatedTaskPatch: TaskPatch = {
      id: task.id,
      executed_on: new Date(),
      executed_by_id: userId,
      executed_at: {long: longitude, lat: latitude},
    };
    this.updateTask(updatedTaskPatch);
  }

  initAppToolbar() {
    const {run} = this.state;
    const title = run?.name ?? 'Run Stops';
    const backTitle = 'Schedule';
    const backUrl = '/schedule';
    this.appToolbarRef?.current?.initAppToolbar({
      title,
      backUrl,
      backTitle,
      rightTemplate: this.appToolbarRightTemplate,
    });
  }

  /**
   * @FIXME: there are better ways to do this.
   * Disable redirect when user clicked "done" button.
   * @param e
   * @param order
   * @param run
   * @param task
   */
  onRunClick(e: React.MouseEvent<HTMLElement>, order: Order | undefined, run: Run | undefined, task: Task | undefined) {
    if ((order?.id || task?.id) && run?.id) {
      const target = e.nativeEvent.target as HTMLElement;
      if (!target.closest('button')) {
        this.setState({
          redirectInfo: {
            orderId: order?.id,
            runId: run?.id,
            taskId: task?.id,
          },
        });
      }
    }
  }

  onLocationDetailClick(stopLocation: Location | undefined) {
    this.setState({locationDetail: stopLocation, showLocationDetail: true});
  }

  appToolbarRightTemplate() {
    const {history, match} = this.props;
    const runId = match.params.id;
    const menuItems: MenuItem[] = [
      {
        label: 'Re-Order Stops',
        faIcon: faLineHeight,
        command: () => {
          history.push(`/run/${runId}/re-order-stops`);
        },
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
    ];
    return (
      <div className="app-toolbar-part p-text-right">
        <Menu model={menuItems} popup ref={this.menuRef} id="app_top_tooltip_right_popup_menu" />
        <Button
          className={'p-py-1 p-px-3'}
          icon={<FontAwesomeIcon icon={faBars} />}
          onClick={event => this.menuRef?.current?.toggle(event)}
          aria-controls="app_top_tooltip_right_popup_menu"
          aria-haspopup
        />
      </div>
    );
  }

  render() {
    const {history} = this.props;
    // @FIXME: there are better ways to do this.
    if (this.state.redirectInfo) {
      const redirectInfo = this.state.redirectInfo;
      if (redirectInfo.orderId) {
        return <Redirect to={`/run/${redirectInfo.runId}/order/${redirectInfo.orderId}`} />;
      } else {
        return <Redirect to={`/run/${redirectInfo.runId}/task/${redirectInfo.taskId}`} />;
      }
    }
    const {run, stops, freightOrdersMap, loading, ordersDropLineUpMap} = this.state;

    const getStopNamePrefix = (locationType: LocationType) => {
      switch (locationType) {
        case 'dealership':
          return 'D';
        case 'end-customer':
          return 'C';
        case 'warehouse':
          return 'W';
        case 'factory':
          return 'F';
        case 'service':
          return 'S';
        case 'other':
          return 'O';
        default:
          return 'O';
      }
    };

    return (
      <>
        {loading && (
          <>
            <div className="overlay">
              <ProgressSpinner className="overlay-spinner" />
            </div>
          </>
        )}
        <ScrollPanel id="run_component">
          {stops &&
            stops.map((stop, index) => {
              const tasks = (stop.tasks?.filter(t => t !== null) ?? []).sort((a, b) => {
                const dropConstants = ['drop', 'final-drop'];
                if (dropConstants.includes(a.type)) {
                  if (dropConstants.includes(b.type)) {
                    //alphabetical order

                    return (freightOrdersMap[a.freight_order_id!]?.order?.reference ?? '') <
                      (freightOrdersMap[b.freight_order_id!]?.order?.reference ?? '')
                      ? -1
                      : 1;
                  } else {
                    //task with type drop is always first
                    return -1;
                  }
                } else {
                  if (dropConstants.includes(b.type)) {
                    //task with type drop is always first
                    return 1;
                  }
                }

                //pickup sort
                if (a.type === 'pickup') {
                  if (b.type === 'pickup') {
                    if (ordersDropLineUpMap[b.freight_order_id!] === ordersDropLineUpMap[a.freight_order_id!]) {
                      //alphabetical order
                      return (freightOrdersMap[a.freight_order_id!]?.order?.reference ?? '') >
                        (freightOrdersMap[b.freight_order_id!]?.order?.reference ?? '')
                        ? -1
                        : 1;
                    } else {
                      return ordersDropLineUpMap[b.freight_order_id!] - ordersDropLineUpMap[a.freight_order_id!];
                    }
                  }
                }
                //default
                return a.line_up - b.line_up;
              });
              const executedTasks = tasks.filter(t => t.executed_on !== null) ?? [];

              const panelTemplate = (options: PanelHeaderTemplateOptions) => {
                const toggleIcon = (
                  <FontAwesomeIcon
                    icon={options.collapsed ? ['fal', 'chevron-down'] : ['fal', 'chevron-up']}
                    size={'2x'}
                  />
                );

                const className = `${options.className}`;
                const titleClassName = `${options.titleClassName}`;
                const stopLocation = stop.stop_location;

                return (
                  <>
                    <div className={className}>
                      <div className="p-d-flex p-ai-center p-jc-between">
                        <span className={titleClassName + ' p-mr-2 p-as-center'}>stop</span>
                      </div>
                      <div className="p-grid p-dir-col p-ai-center p-jc-between">
                        <div
                          className="p-col p-py-0 p-text-center"
                          onClick={() => this.onLocationDetailClick(stopLocation)}
                        >
                          <span className={titleClassName + ' p-mr-2 stop-title'}>
                            {getStopNamePrefix(stopLocation?.type ?? 'other')}
                          </span>
                          <span className={titleClassName + ' stop-title'}>{stopLocation?.name ?? ''}</span>
                        </div>
                        <div
                          className="p-col p-py-0 p-text-center"
                          onClick={() => this.onLocationDetailClick(stopLocation)}
                        >
                          <span className={titleClassName + ' stop-subtitle'}>
                            {`${stopLocation?.address?.street ?? ''}, ${stopLocation?.address?.suburb ?? ''}`}
                          </span>
                        </div>
                        <div
                          className="p-col p-py-0 p-text-center"
                          onClick={() => this.onLocationDetailClick(stopLocation)}
                        >
                          <span className={titleClassName + ' stop-subtitle stop-instructions'}>
                            {`${stopLocation?.instructions ?? '\u00A0'}`}
                          </span>
                        </div>
                      </div>
                      <div className="p-d-flex p-ai-center p-jc-between">
                        <span className={titleClassName + ' p-mr-3'}>{`${executedTasks.length}/${tasks.length}`}</span>
                        <button className={'p-button p-button-sm'} onClick={options.onTogglerClick}>
                          <span className={'p-button-icon'}>{toggleIcon}</span>
                        </button>
                      </div>
                    </div>
                  </>
                );
              };

              const proof = stop.proof;
              const proofButtonClass = proof ? 'p-button-success' : '';

              return (
                <Panel
                  key={'stop' + index + stop.id?.toString()}
                  className={index !== stops.length - 1 ? 'p-mb-2' : ''}
                  headerTemplate={panelTemplate}
                  toggleable
                >
                  {tasks &&
                    tasks.map((task, index) => {
                      const cardMargin = index !== tasks.length - 1 ? 'p-mb-3' : '';
                      const executedTask = task.executed_on !== null;

                      const freightOrder = freightOrdersMap[task.freight_order_id!];

                      const order = freightOrder?.order;
                      const factoryOrder = freightOrder?.factory_order;

                      const orderShipmentItems =
                        freightOrder?.shipment_items?.filter(si => si && si.order_id === order?.id) ?? [];

                      const boxesType =
                        factoryOrder?.product_line === 'Colourvue'
                          ? 'cv'
                          : factoryOrder?.product_line === 'Shadesol'
                            ? 'ss'
                            : factoryOrder?.product_line === 'Curtains'
                              ? 'cu'
                              : 'sh';
                      let icon: ReactNode;
                      if (task.type === 'pickup') {
                        icon = <FontAwesomeIcon icon={['fas', 'arrow-alt-to-top']} size={'2x'} color="#5486ea" />;
                      } else if (task.type === 'drop' || task.type === 'final-drop') {
                        icon = <FontAwesomeIcon icon={['fas', 'arrow-alt-to-bottom']} size={'2x'} color="#29c76f" />;
                      } else {
                        icon = <FontAwesomeIcon icon={['fas', 'note']} size={'2x'} color="#ffe342" />;
                      }

                      const noteElement = freightOrder?.delivery_note?.length ? (
                        <FontAwesomeIcon className={'p-mr-1'} icon={faMessageExclamation} />
                      ) : undefined;
                      const executedClass = executedTask ? 'text-line-through' : '';
                      const card = (
                        <Card key={'task' + index + task?.id?.toString()} className={cardMargin}>
                          <div className="p-d-flex p-ai-center p-jc-between">
                            <div className="p-d-flex p-p-0 p-col-1">{icon}</div>
                            {order ? (
                              <>
                                <div className="p-d-flex p-p-0 p-col-8 p-flex-wrap">
                                  {noteElement}
                                  <span className={`p-pr-2 ${executedClass}`}>{freightOrder?.id ?? ''}</span>
                                  <span className={`p-pr-2 account-bold ${executedClass}`}>
                                    {freightOrder?.owner_company?.account_number ?? ''}
                                  </span>
                                  <span className={`p-pr-2 ${executedClass}`}>{order?.reference ?? ''}</span>
                                </div>
                                <div className="p-d-flex p-p-0 p-col-1">
                                  <span className={`${executedClass}`}>
                                    {`${orderShipmentItems.length} ${boxesType}`}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className="p-d-flex p-p-0 p-col-9 p-flex-wrap">
                                <span className={`${executedClass}`}>{task.description}</span>
                              </div>
                            )}
                            {!executedTask ? (
                              <div className="p-d-flex p-p-0 p-jc-end p-col-2">
                                <Button
                                  className="p-button p-component p-button-plain"
                                  onClick={() => this.setTaskCompletion(task)}
                                >
                                  <span className="p-button-label">Done</span>
                                </Button>
                              </div>
                            ) : (
                              <div className="p-col-2" />
                            )}
                          </div>
                        </Card>
                      );
                      return order ? (
                        <div onClick={e => this.onRunClick(e, order, run, undefined)}>{card}</div>
                      ) : (
                        <div onClick={e => this.onRunClick(e, undefined, run, task)}>{card}</div>
                      );
                    })}
                  <div className="p-grid">
                    <div className="p-col-2 p-offset-2">
                      {run?.stage !== 'Done' &&
                        (stop.stop_location?.type === 'factory' || stop.stop_location?.type === 'warehouse') && (
                          <Button
                            className={'p-mt-3 p-py-1'}
                            style={{width: '100%'}}
                            icon={<FontAwesomeIcon icon={faHandHoldingBox} size={'2xl'} />}
                            onClick={() => {
                              history.push(`/run/${run?.id}/stop/${stop?.id}/add-orders-to-run`);
                            }}
                          />
                        )}
                    </div>
                    <div className="p-col-2 p-offset-4">
                      <Button
                        className={`p-mt-3 p-py-1 ${proofButtonClass}`}
                        style={{width: '100%'}}
                        icon={<FontAwesomeIcon icon={faCamera} size={'2xl'} />}
                        onClick={() => {
                          history.push(`/run/${run?.id}/stop/${stop.id}/proof/${proof?.id ?? ''}`);
                        }}
                      />
                    </div>
                  </div>
                </Panel>
              );
            })}
          <Toast ref={this.toast} />
          <LocationDetail
            location={this.state.locationDetail}
            showDialog={this.state.showLocationDetail}
            onHide={() => this.setState({showLocationDetail: false})}
          />
        </ScrollPanel>
      </>
    );
  }
}

export default withRouter(RunComponent);
