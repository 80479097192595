import config from '../config/config';
import {ApiListResponse, ApiResponse, QueryParameter, Run, RunPatch} from 'two-core';
import {ApiService, AuthService} from 'two-app-ui';

class RunsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.runs ?? '';
  }

  async getRuns(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async getRun(id: string): Promise<Run> {
    return this.get(this.endpoint + '/' + id)
      .then(data => {
        return Promise.resolve((data as ApiResponse).body as Run);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateRun(id: number, data: RunPatch): Promise<Run> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Run);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default RunsService;
