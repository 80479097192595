import config from '../config/config';
import {FreightProof} from 'two-core';
import {AuthService, FileService} from 'two-app-ui';
import {AxiosResponse} from 'axios';

class FreightProofsService extends FileService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.freightProofs ?? '';
  }

  async createFreightProof(data: Partial<FreightProof>): Promise<FreightProof> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as FreightProof);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateFreightProof(id: number, data: Partial<FreightProof>): Promise<FreightProof> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as FreightProof);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async uploadPhoto(proofId: number, file: File): Promise<AxiosResponse<any>> {
    const bucket = config().aws.proofBucket ?? '';
    const filePath = `freight_proofs/${proofId}/photos`;
    return this.uploadFile(bucket, filePath, file);
  }

  async deletePhoto(proofId: number, url: string) {
    const fileName = url.split(`photos/`)[1];
    const filePath = `freight_proofs/${proofId}/photos/${fileName}`;
    const bucket = config().aws.proofBucket ?? '';
    return await this.deleteFile(bucket, filePath);
  }

  async uploadSignature(proofId: number, file: File): Promise<AxiosResponse<any>> {
    const bucket = config().aws.proofBucket ?? '';
    const filePath = `freight_proofs/${proofId}/signature`;
    return this.uploadFile(bucket, filePath, file);
  }
}

export default FreightProofsService;
