import React from 'react';
import {Toolbar} from 'primereact/toolbar';
import './AppToolbar.scss';
import {LeftPart} from './LeftPart';
import {CenterPart} from './CenterPart';
import {RightPart} from './RightPart';

interface State {
  title?: string;
  backUrl?: string;
  backTitle?: string;
  leftTemplate?: () => React.JSX.Element;
  rightTemplate?: () => React.JSX.Element;
}
export class AppToolbar extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};

    this.initAppToolbar = this.initAppToolbar.bind(this);
  }
  initAppToolbar(params: {
    title?: string;
    backUrl?: string;
    backTitle?: string;
    leftTemplate?: () => React.JSX.Element;
    rightTemplate?: () => React.JSX.Element;
  }) {
    this.setState(() => ({
      title: params.title,
      backUrl: params.leftTemplate ? undefined : params.backUrl,
      backTitle: params.leftTemplate ? undefined : params.backTitle,
      leftTemplate: params.leftTemplate,
      rightTemplate: params.rightTemplate,
    }));
  }

  setLeftTemplate(template: () => React.JSX.Element) {
    this.setState(() => ({leftTemplate: template}));
  }

  setRightTemplate(template: () => React.JSX.Element) {
    this.setState(() => ({rightTemplate: template}));
  }

  render() {
    const {title, leftTemplate, rightTemplate, backTitle, backUrl} = this.state;
    return (
      <div id="app_toolbar" className="p-shadow-2 p-d-flex p-flex-column p-py-1">
        <Toolbar
          className="p-p-2"
          left={
            <div className="p-d-flex p-jc-between p-ai-center w-100">
              <LeftPart backUrl={backUrl} backTitle={backTitle} template={leftTemplate} />
              <CenterPart title={title ?? ''} />
              <RightPart template={rightTemplate} />
            </div>
          }
        />
      </div>
    );
  }
}
